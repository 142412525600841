import { toRef } from 'vue'
import { useNuxtApp } from '#app'
import { useAPI, useHydrationStore, useNavigate, useSharedPromise } from '#imports'
// import { getUrlWithTrailingSlashes } from '@/utils/url'

import type { GetNavigationMenuResponseData } from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_api_pb'
import type { Category } from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_pb.js'
import type { SectionGroup } from '@/modules/nuxt-api/models/SectionGroup'

interface State {
  catalogCategories: SectionGroup[]
  navigationMenu: GetNavigationMenuResponseData.AsObject | undefined
}

export function useCategories () {
  const api = useAPI()
  const state = useHydrationStore<State>('categories-store', {
    catalogCategories: [],
    navigationMenu: undefined
  }, { disableHydration: true })

  async function getNavigationMenu () {
    const nuxtApp = useNuxtApp()
    const { value, addToCache } = await useCacheData<GetNavigationMenuResponseData.AsObject>('navigation-menu')

    if (value) {
      return value
    }

    if (!state.value.navigationMenu?.popularSections?.sectionsList?.length || !state.value.navigationMenu.sectionGroupsList?.length) {
      const { getNavigationMenu } = api.catalog()
      state.value.navigationMenu = await nuxtApp.runWithContext(() => useSharedPromise('navigation-menu', getNavigationMenu))
    }

    if (state.value.navigationMenu?.popularSections?.sectionsList?.length && state.value.navigationMenu.sectionGroupsList?.length) {
      await addToCache(state.value.navigationMenu)
    }

    return state.value.navigationMenu
  }

  async function getCategories () {
    const nuxtApp = useNuxtApp()
    const { value, addToCache } = await useCacheData<Category.AsObject[]>('categories-list')
    let data = value

    if (!value) {
      const { getCategories } = api.catalog()
      const categories = await nuxtApp.runWithContext(() => getCategories())

      if (categories?.length) {
        await addToCache(categories)
        data = categories
      }
    }

    data?.filter(v => v.code !== 'chairty')

    data?.push(...[
      { code: 'champagnes-and-sparkling', title: '' },
      { code: 'novelty', title: '' },
      { code: 'bundles', title: '' },
      { code: 'spirit', title: '' },
      { code: 'gifts', title: '' },
      { code: 'other-drinks', title: '' },
      { code: 'cidre', title: '' },
      { code: 'gift_pack', title: '' },
      { code: 'rush_buy', title: '' },
      { code: 'kashasa', title: '' },
      { code: 'wine-sets', title: '' },
      { code: 'promo', title: '' }
    ])

    return data
  }

  async function getCatalogCategories () {
    if (state.value.catalogCategories?.length) { return state.value.catalogCategories }

    const menu = await getNavigationMenu()
    const res: SectionGroup[] = menu?.sectionGroupsList?.filter?.(el => el.displayType !== 3) ?? []

    if (menu?.popularSections) {
      res.unshift({ ...menu.popularSections, sectionsList: menu.popularSections.sectionsList?.slice(0, 9) ?? [] })
    }

    for (let i = 0; i < res.length; i++) {
      res[i].sectionsList = res[i].sectionsList.map(el => ({
        ...el,
        image: {
          ...el.image,
          url: getUrlWithTrailingSlashes(`${el.image?.url.replace('%w', '250').replace('%h', '250')}`, false)
        }
      }))
    }

    state.value.catalogCategories = res
  }

  async function getHeaderCategories () {
    const { getUrl } = useNavigate()
    const navigationMenu = await getNavigationMenu()

    const sectionsList = navigationMenu?.popularSections?.sectionsList || []

    return sectionsList.map((el) => {
      const item: { url: string, title: string, icon?: string, accent?: boolean } = {
        url: getUrl(el.path?.path || ''),
        title: el.title
      }

      if (el.icon) {
        item.icon = `i-${el.icon}`
      }

      if (el.path?.path.includes('promo')) {
        item.accent = true
      }

      return item
    })
  }

  // async function initializeMenu () {
  //   const navigationMenu = await getNavigationMenu()
  //
  //   if (navigationMenu) {
  //     state.value.catalogCategories = getCatalogCategories(navigationMenu)
  //   }
  // }

  return {
    getCategories,
    getNavigationMenu,
    getHeaderCategories,
    getCatalogCategories,

    catalogCategories: toRef(state.value, 'catalogCategories')
  }
}
